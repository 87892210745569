var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"organization-card"},[(_vm.cardType === 'deleting')?_c('button',{staticClass:"button-icon",on:{"click":function($event){return _vm.$emit('delete', _vm.organization)}}},[_c('span',{staticClass:"organization-card__icon"})]):_vm._e(),_c('h4',{staticClass:"organization-card__name"},[_vm._v(_vm._s(_vm.organization.name))]),_c('p',{staticClass:"organization-card__info"},[_vm._v(" "+_vm._s(_vm.organization.address)+" ")]),(_vm.cardType === 'deleting')?_c('p',{staticClass:"organization-card__date"},[(_vm.isShowWarningIcon)?_c('span',{staticClass:"organization-card__icon-warning icon-warning"}):_vm._e(),_vm._v(" Действителен до: "+_vm._s(_vm.bookedCompanyExpiresAt)+" ")]):_vm._e(),(_vm.cardType === 'adding')?[_c('div',{staticClass:"organization-card__add adding"},[(!_vm.checkedCompany)?_c('main-button',{staticClass:"adding__button-check",attrs:{"title":'Проверить доступность',"color":'dark',"padding":'13px 24px',"loading":_vm.checkingCompany},on:{"click":function($event){return _vm.checkCompanyRequest(_vm.organization.inn)}}}):_vm._e(),(
          _vm.checkedCompany &&
          (_vm.companyAvailabilityStatus === 'available' ||
            _vm.companyAvailabilityStatus === 'available-count')
        )?_c('div',{staticClass:"adding__box-btn"},[_c('main-button',{staticClass:"adding__button-add",attrs:{"title":'Добавить в план',"color":'dark',"padding":'13px 24px',"loading":_vm.bookingCompany},on:{"click":function($event){return _vm.bookCompanyRequest(_vm.organization.inn)}}}),_c('p',[_vm._v("Организация свободна и будет закреплена за вами на один месяц")])],1):_vm._e(),(_vm.checkedCompany && _vm.companyAvailabilityStatus === 'available')?_c('p',{staticClass:"adding__text"},[_vm._v(" Вы можете добавить организацию в свой план продаж не более 2-х раз подряд ")]):_vm._e(),(
          _vm.checkedCompany && _vm.companyAvailabilityStatus === 'available-count'
        )?_c('p',{staticClass:"adding__text"},[_vm._v(" После добавления этой организации в ваш план продаж следующее добавление будет доступно через 30 дней ")]):_vm._e(),(_vm.checkedCompany && _vm.companyAvailabilityStatus === 'limit')?_c('p',{staticClass:"adding__error"},[_vm._v(" Добавление этой организации станет доступно "+_vm._s(_vm.companyExpiresAt)+" ")]):_vm._e(),(_vm.checkedCompany && _vm.companyAvailabilityStatus === 'not-available')?_c('p',{staticClass:"adding__error"},[_vm._v(" Организация занята до "+_vm._s(_vm.companyExpiresAt)+" ")]):_vm._e(),(_vm.checkedCompany && _vm.companyAvailabilityStatus === 'already-use')?_c('p',{staticClass:"adding__error"},[_vm._v(" Организация уже зарегистрирована ")]):_vm._e()],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }