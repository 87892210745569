<script>
import { mapActions } from "vuex";
import MainTitleBack from "@/components/helpers/MainTitleBack.vue";
import MainSearch from "@/components/helpers/MainSearch.vue";
import OrganizationCard from "@/components/OrganizationCard.vue";

export default {
  components: {
    MainTitleBack,
    MainSearch,
    OrganizationCard,
  },

  data() {
    return {
      search: "",
      searchRequest: "",

      companies: [],
      loadingCompanies: false,
    };
  },

  methods: {
    ...mapActions({
      searchCompanies: "tsp/searchCompanies",
    }),

    searchCompaniesRequest(value) {
      this.searchRequest = value;
      this.loadingCompanies = true;

      this.searchCompanies({ search: value })
        .then((response) => {
          this.companies = response.data.data;
        })
        .finally(() => {
          this.loadingCompanies = false;
        });
    },

    clearSearchResults() {
      this.search = "";
      this.companies = [];
    },
  },
};
</script>

<template>
  <div class="sales-plan-add-organization default-content-padding">
    <main-title-back
      class="sales-plan-add-organization__title"
      :title="'Добавление организации в план'"
      :href="'SalesPlan'"
      :button-text="'Назад к плану продаж'"
    />

    <main-search
      v-model="search"
      class="sales-plan-add-organization__search"
      :placeholder="'Поиск по названию или ИНН'"
      :background-color="'#fff'"
      :loading="loadingCompanies"
      @search="searchCompaniesRequest(search)"
      @clear="clearSearchResults"
    />

    <p v-if="!companies.length" class="sales-plan-add-organization__notice">
      Введите юридическое название или ИНН организации, которую хотите добавить
      в свой план. Вы увидите в списке только те организации, которые не заняты
      другими пользователями.
    </p>

    <div v-else class="sales-plan-add-organization__search-results">
      <p>Найдено по «{{ searchRequest }}»</p>

      <organization-card
        v-for="company in companies"
        :key="company.inn"
        :card-type="'adding'"
        :organization="company"
        @organisationBooked="
          $router.push({ name: 'SalesPlan', params: { company: company } })
        "
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sales-plan-add-organization {
  padding-bottom: $space-xxl;
  padding-top: $space-xxl;

  &__title {
    margin-bottom: $space-xxxl;
  }

  &__search {
    max-width: 644px;
  }

  &__notice {
    margin-top: $space-l;
    @include text-2;
    color: $dark-primary;
    max-width: 500px;
  }

  &__search-results {
    margin-top: 40px;
    max-width: 644px;

    p {
      margin-bottom: $space-xl;
    }
  }
}
</style>
