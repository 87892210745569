<script>
import { mapActions } from "vuex";
import { format } from "date-fns";
import MainButton from "@/components/helpers/MainButton.vue";
import { DateTime } from "luxon";
import formatDateTimezone from "@/package/helpers/format-date-timezone";

export default {
  components: { MainButton },

  props: {
    organization: {
      type: Object,
      required: true,
    },

    cardType: {
      type: String,
      default: "deleting",
    },
  },

  data() {
    return {
      checkedCompany: false,
      companyAvailabilityStatus: "",
      checkingCompany: false,
      bookingCompany: false,

      companyExpiresAt: "",
    };
  },

  computed: {
    bookedCompanyExpiresAt() {
      return format(new Date(this.organization.expires_at), "dd.MM.yyyy");
    },

    isShowWarningIcon() {
      // Преобразуем дату которая приходит с сервера
      const serverDate = formatDateTimezone(this.organization.expires_at);
      // Получаем текущую дату
      const now = DateTime.now();
      // Разница между текущей датой и датой с сервера
      const diff = serverDate.diff(now, "days").days;

      return diff < 7;
    },
  },

  methods: {
    ...mapActions({
      checkCompany: "tsp/checkCompany",
      bookCompany: "tsp/bookCompany",
    }),

    checkCompanyRequest(inn) {
      this.checkingCompany = true;

      this.checkCompany({ inn: inn })
        .then((res) => {
          if (res.data.data?.booking_count >= 1) {
            this.companyAvailabilityStatus = "available-count";
          } else {
            this.companyAvailabilityStatus = "available";
          }
          // this.checkedCompany = true;
        })
        .catch((error) => {
          if (error.response.data.data) {
            this.companyExpiresAt = format(
              new Date(error.response.data.data.expires_at),
              "dd.MM.yyyy"
            );

            const message = error.response.data.message;

            if (message === "company_booking.not_available") {
              this.companyAvailabilityStatus = "not-available";
              return;
            }

            if (message === "company_booking.limit") {
              this.companyAvailabilityStatus = "limit";
              return;
            }

            if (message === "company_booking.maximum") {
              this.companyAvailabilityStatus = "maximum";
            }
          } else {
            this.companyAvailabilityStatus = "already-use";
          }
          // this.checkedCompany = true;
        })
        .finally(() => {
          this.checkedCompany = true;
          this.checkingCompany = false;
        });
    },

    bookCompanyRequest(inn) {
      this.bookingCompany = true;

      this.bookCompany({ inn: inn })
        .then(() => {
          this.$emit("organisationBooked");
        })
        .finally(() => {
          this.bookingCompany = false;
        });
    },
  },
};
</script>

<template>
  <div class="organization-card">
    <button
      v-if="cardType === 'deleting'"
      class="button-icon"
      @click="$emit('delete', organization)"
    >
      <span class="organization-card__icon"></span>
    </button>

    <h4 class="organization-card__name">{{ organization.name }}</h4>
    <p class="organization-card__info">
      {{ organization.address }}
    </p>
    <p v-if="cardType === 'deleting'" class="organization-card__date">
      <span
        v-if="isShowWarningIcon"
        class="organization-card__icon-warning icon-warning"
      />
      Действителен до: {{ bookedCompanyExpiresAt }}
    </p>

    <template v-if="cardType === 'adding'">
      <div class="organization-card__add adding">
        <main-button
          v-if="!checkedCompany"
          class="adding__button-check"
          :title="'Проверить доступность'"
          :color="'dark'"
          :padding="'13px 24px'"
          :loading="checkingCompany"
          @click="checkCompanyRequest(organization.inn)"
        />
        <!--        TO DO нужны доработки бэка-->
        <div
          v-if="
            checkedCompany &&
            (companyAvailabilityStatus === 'available' ||
              companyAvailabilityStatus === 'available-count')
          "
          class="adding__box-btn"
        >
          <main-button
            class="adding__button-add"
            :title="'Добавить в план'"
            :color="'dark'"
            :padding="'13px 24px'"
            :loading="bookingCompany"
            @click="bookCompanyRequest(organization.inn)"
          />

          <p>Организация свободна и будет закреплена за вами на один месяц</p>
        </div>

        <p
          v-if="checkedCompany && companyAvailabilityStatus === 'available'"
          class="adding__text"
        >
          Вы можете добавить организацию в свой план продаж не более 2-х раз
          подряд
        </p>

        <p
          v-if="
            checkedCompany && companyAvailabilityStatus === 'available-count'
          "
          class="adding__text"
        >
          После добавления этой организации в ваш план продаж следующее
          добавление будет доступно через 30 дней
        </p>

        <p
          v-if="checkedCompany && companyAvailabilityStatus === 'limit'"
          class="adding__error"
        >
          Добавление этой организации станет доступно {{ companyExpiresAt }}
        </p>

        <p
          v-if="checkedCompany && companyAvailabilityStatus === 'not-available'"
          class="adding__error"
        >
          Организация занята до {{ companyExpiresAt }}
        </p>

        <p
          v-if="checkedCompany && companyAvailabilityStatus === 'already-use'"
          class="adding__error"
        >
          Организация уже зарегистрирована
        </p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.organization-card {
  background-color: $background-white;
  border-radius: 8px;
  padding: $space-m 48px $space-m $space-m;
  position: relative;

  &:not(:last-child) {
    margin-bottom: $space-m;
  }

  &__icon {
    mask: url("../assets/icons/close_bold.svg") 0 0 / cover no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: $dark-sixth;
    position: absolute;
    right: $space-m;
    top: $space-m;
  }

  &__name {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-xs;
  }

  &__info {
    @include text-2;
    color: $dark-fifth;
    text-transform: uppercase;
    margin-bottom: $space-m;
  }

  &__date {
    @include text-2;
    color: $dark-primary;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .adding {
    display: flex;
    //align-items: center;
    flex-direction: column;
    gap: $space-m;

    &__button-check {
      max-width: 252px;
    }

    &__button-add {
      max-width: 200px;
      margin-right: $space-m;
    }

    &__info {
      @include text-2;
      color: $green;
      max-width: 330px;
    }

    &__error {
      @include text-2;
      color: $red;
    }

    &__text {
      @include text-2;
      color: $dark-fifth;
    }

    &__box-btn {
      display: flex;
      align-items: center;
      gap: 16px;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      > p {
        @include text-2;
        color: $money-cv;
      }
    }

    ::v-deep button {
      white-space: nowrap;
    }
  }

  &__icon-warning {
    width: 24px;
    aspect-ratio: 1 / 1;
    background: $red;
  }
}

@media (max-width: 576px) {
  .organization-card {
    .adding {
      flex-direction: column;
      align-items: flex-start;

      &__button-add {
        margin-right: 0;
      }
    }
  }
}
</style>
